<!-- eslint-disable -->
<template>
  <div class="message-count abroad timeMessage">
    <p class="index-title">境外敏感信息</p>
    <!-- 头部  -->
    <div class="message-top">
      <!-- 舆情 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部数据" name="second"></el-tab-pane>
        <el-tab-pane label="涉本地数据" name="first"></el-tab-pane>
      </el-tabs>
      <div style="margin-top: 15px" class="message-region clear">
        <span class="message-top-title">媒体类型</span>
        <div class="el-checkbox-group">
          <el-checkbox v-model="mediaAll" @change="handleCheckAreasChanges">全选</el-checkbox>
          <el-checkbox-group v-model="checkedMedia" @change="mediaTypeChecked">
            <el-checkbox v-for="(item, index) in media" :label="item" :key="index">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="zhankai-change" @click="zhankaiChange">
          <span class="xia-span">{{ zhankai ? "收起" : "展开" }}</span>
          <span>
            <img class="xia-span-img" src="../../assets/img/views/more.png" alt v-show="zhankai" />
            <img src="../../assets/img/views/xia.png" alt v-show="!zhankai" class="xia-img" />
          </span>
        </div>
      </div>
      <div class="line"></div>
      <!-- 区域 -->
      <div :class="['animation', zhankai ? 'shouHeight' : 'defultHeight']">
        <div class="message-region clear">
          <span class="message-top-title">媒体归属</span>
          <div class="el-checkbox-group">
            <el-checkbox v-model="allMediaAscrip" @change="allMediaAscription">全选</el-checkbox>
            <el-checkbox-group v-model="checkedAscription" @change="mediaAscriptionChange">
              <el-checkbox v-for="(item, index) in mediaAscription" :label="item" :key="index">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="line"></div>
        <div class="message-region clear">
          <span class="message-top-title">语言类型</span>
          <div class="el-checkbox-group">
            <el-checkbox v-for="(itme, index) in language" v-model="itme.checked"
              @change="handleCheckAreasChangessss(itme)" :key="index">{{ itme.value }}</el-checkbox>
          </div>
        </div>
        <div class="line"></div>
        <!-- 来源媒体 -->
        <div class="message-region clear">
          <span class="message-top-title huanjie">媒体阵营</span>
          <div class="el-checkbox-group">
            <el-checkbox v-model="allCheckmediazy" @change="handleCheckAreasChangesssss">全选</el-checkbox>
            <el-checkbox-group v-model="checkedMediaCamp" @change="mediaCampChacked">
              <el-checkbox v-for="(item, index) in mediaCamp" :label="item" :key="index">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="line"></div>
        <!-- 敏感性 -->
        <div v-if="activeName == 'first'" class="message-region clear">
          <span class="message-top-title" style="letter-spacing: 7px; margin-right: 33px">敏感性</span>
          <div class="el-checkbox-group">
            <el-checkbox v-model="sensitiveAll" @change="sensitiveAllChange">全选</el-checkbox>
            <el-checkbox-group v-model="sensitiveList" @change="sensitiveGroup">
              <el-checkbox v-for="(item, index) in sensitiveData" :label="item" :key="index">{{ item.label
                }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div v-if="activeName == 'first'" class="line"></div>
      </div>
      <!-- 标题 //  时间 // 已预警-->
      <div class="message-time">
        <!-- 表单 -->
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item class="select-item">
            <el-select class="select" v-model="formInline.region">
              <el-option label="标题" value="title"></el-option>
              <el-option label="正文" value="content"></el-option>
              <el-option label="作者" value="sourceAuthor"></el-option>
              <el-option label="来源" value="source"></el-option>
            </el-select>
          </el-form-item>
          <el-input clearable class="input" size="medium" placeholder="请输入完整的单词/词语/句子进行模糊搜索"
            v-model="formInline.input1">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <span class="el_font">时间</span>
          <el-date-picker v-model="formInline.value1[0]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始日期">
          </el-date-picker>
          -
          <el-date-picker v-model="formInline.value1[1]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束日期">
          </el-date-picker>
          <el-checkbox v-if="activeName == 'first'" class="yujing" v-model="checked">已预警</el-checkbox>
          <div style="float: right">
            <el-button type="primary" style="width: 60px" size="small" @click="search">查询</el-button>
            <el-button size="small" class="chongzhi" @click="chongzhi">重置</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 头部  -->
    <!-- 底部 -->
    <div style="padding-bottom: 24px">
      <!-- 精简按钮 || 列表按钮  -->
      <div style="margin-top: 16px;width: 100%;display: flex;justify-content: end;">
        <el-radio-group v-model="radioValue" size="medium" @change="changeradio">
          <el-radio-button label="1">
            <img v-if="radioValue == '2'" height="13px" src="../../assets/img/views/jinjianBlack.png" alt="" />
            <img v-else height="13px" src="../../assets/img/views/jinjian.png" alt="" />
            <span v-if="radioValue == '2'" style="color: #666; font-size: 15px; margin-left: 4px">精简</span>
            <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">精简</span>
          </el-radio-button>
          <el-radio-button label="2">
            <img v-if="radioValue == 2" height="13px" src="../../assets/img/views/liebiao.png" alt="" />
            <img v-else height="13px" src="../../assets/img/views/liebiaoBlack.png" alt="" />
            <span v-if="radioValue == '1'" style="color: #666; font-size: 15px; margin-left: 4px">列表</span>
            <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">列表</span>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="message-bottom">
        <!-- 每一个模块 -->
        <div v-loading="loading">
          <div v-if="textData.length != 0">
            <div class="bottom-count" v-for="item in   textData  " :key="item.dataId">
              <!-- 列表 -->
              <template v-if="radioValue == 2">
                <div class="bottom-count-box">
                  <div v-if="activeName == 'second'" class="type english">
                    {{ item.languageType }}
                  </div>
                  <div v-if="activeName == 'first'" :class="['type', item.sensitives == 0 ? 'english' : 'red']">
                    {{ item.sensitives == 0 ? "非敏感" : "敏感" }}
                  </div>
                  <div class="type website">{{ item.mediaType }}</div>
                </div>
                <div class="bottom-count-text">
                  <div class="bottom-comment-box">
                    <div style="display: flex">
                      <span v-show="item.isEarlyWarn == '1'" class="comment-box-yujing">已预警</span>
                      <p v-if="item.titleF" v-html="item.titleF" class="bottom-title" @click="hangel(item)"></p>
                      <p v-html="item.title" class="bottom-title" @click="hangel(item)" v-else></p>
                      <span class="original" @click="lookOriginal(item.website)">
                        <img src="../../assets/img/views/wenzhang.png" alt />
                        <span>查看原文</span>
                      </span>
                      <span style="color:#2e59ec;font-size: 14px;margin-left: 10px;cursor: pointer;"
                        @click="translateYes(item)">
                        <svg style="vertical-align: bottom;" t="1672899721329" class="icon" viewBox="0 0 1024 1024"
                          version="1.1" p-id="5024" width="18" height="18">
                          <path
                            d="M608 416h288c35.36 0 64 28.48 64 64v416c0 35.36-28.48 64-64 64H480c-35.36 0-64-28.48-64-64v-288H128c-35.36 0-64-28.48-64-64V128c0-35.36 28.48-64 64-64h416c35.36 0 64 28.48 64 64v288z m0 64v64c0 35.36-28.48 64-64 64h-64v256.032c0 17.664 14.304 31.968 31.968 31.968H864a31.968 31.968 0 0 0 31.968-31.968V512a31.968 31.968 0 0 0-31.968-31.968H608zM128 159.968V512c0 17.664 14.304 31.968 31.968 31.968H512a31.968 31.968 0 0 0 31.968-31.968V160A31.968 31.968 0 0 0 512.032 128H160A31.968 31.968 0 0 0 128 159.968z m64 244.288V243.36h112.736V176h46.752c6.4 0.928 9.632 1.824 9.632 2.752a10.56 10.56 0 0 1-1.376 4.128c-2.752 7.328-4.128 16.032-4.128 26.112v34.368h119.648v156.768h-50.88v-20.64h-68.768v118.272H306.112v-118.272H238.752v24.768H192z m46.72-122.368v60.48h67.392V281.92H238.752z m185.664 60.48V281.92h-68.768v60.48h68.768z m203.84 488H576L668.128 576h64.64l89.344 254.4h-54.976l-19.264-53.664h-100.384l-19.232 53.632z m33.024-96.256h72.864l-34.368-108.608h-1.376l-37.12 108.608zM896 320h-64a128 128 0 0 0-128-128V128a192 192 0 0 1 192 192zM128 704h64a128 128 0 0 0 128 128v64a192 192 0 0 1-192-192z"
                            fill="#2e59ec" p-id="5025"></path>
                        </svg>
                        翻译
                      </span>
                    </div>

                    <div class="bottom-comment">
                      <span :class="['comment-xiangsi', item.likeAmount ? 'blue' : 'grey',]"
                        v-if="activeName == 'first'">
                        <!-- {{ item.likeAmount ? "有相似" : "无相似" }} -->
                        相似({{ item.likeAmount ? item.likeAmount : "0" }})
                      </span>
                      <span class="comment-weidu" v-if="activeName == 'first'">
                        <i>
                          <img v-if="item.isRead == '1'" src="../../assets/img/views/greenmessage.png" alt />
                          <img v-else src="../../assets/img/views/redmessage.png" alt />
                        </i>
                        <span :class="[item.isRead == '1' ? 'yidu' : ' ']">{{
        item.isRead == "1" ? "已读" : "未读"
      }}</span>
                      </span>
                    </div>
                  </div>
                  <div v-if="item.contentF" v-html="item.contentF" class="bottom-text" @click=" hangel(item)"></div>
                  <div v-else v-html="item.content" class="bottom-text" @click=" hangel(item)"></div>
                  <div class="bottom-text-box">
                    <div class="bottom-text-caozuo">
                      <span class="publics source">
                        媒体类型:
                        {{ item.mediaType ? item.mediaType : "无" }}
                      </span>
                      <span class="publics source">
                        媒体归属:
                        {{ item.mediaOwnership ? item.mediaOwnership : "无" }}
                      </span>
                      <span class="publics source">
                        媒体阵营:
                        {{ item.mediaCamp ? item.mediaCamp : "无" }}
                      </span>
                      <span v-if="activeName == 'first'" class="publics source">
                        语言类型:
                        {{ item.languageType ? item.languageType : "无" }}
                      </span>
                      <span class="publics bottom-gengduo">
                        来源/作者:
                        <span v-html="item.source ? item.source : '无'"></span>
                        <span>/</span>
                        <span v-html="item.sourceAuthor ? item.sourceAuthor : '无'"></span>
                      </span>
                      <span v-if="item.keyword && activeName == 'first'" class="publics bottom-key">
                        关键字：
                        <span v-for="(  ite, indx  ) in   item.keywordList  " :class="[
        ite != item.keywordList[item.keywordList.length - 1]
          ? 'styleMargin'
          : '',
      ]
        " :key="indx">{{ ite }}</span>
                        <el-tooltip v-if="item.keyword && item.keywordArry.length > 3" class="item" effect="dark"
                          placement="top">
                          <div slot="content" v-html="item.keyword"></div>
                          <span class="gengduo-link">(更多)</span>
                        </el-tooltip>
                      </span>
                      <span v-if="!item.keyword && activeName == 'first'" class="publics bottom-key">
                        关键字：
                        <span>无</span>
                      </span>
                    </div>
                    <span class="publics bottom-time">
                      {{ item.releaseTime ? item.releaseTime : "无" }}
                    </span>
                  </div>
                  <div class="bottom-operation">
                    <!-- 设置标签 -->
                    <div>
                      <el-select v-if="activeName == 'first'" class="select-biaoqian" v-model="item.setLabel"
                        placeholder="设置标签" size="small" @change=" changeLabel(item)">
                        <el-option v-for="(  item, index  ) in   biaoqianList  " :key="index" :label="item.labelName"
                          :value="item.labelId"></el-option>
                      </el-select>
                    </div>

                    <div class="operation-box">
                      <!-- 数据上报 -->
                      <div v-if="$store.state.user.isreport && activeName == 'first'" :class="{ 'operation-shoucang': true, 'my-caozuo': true, 'operation-disabled': item.isReport == 1, }
        " @click=" dataReport(item)">
                        <span>
                          <span>
                            <img style="width: 16px; height: 16px"
                              src="../../assets/img/views/monitoringScheme/sahngchuan.png" alt />
                          </span>
                        </span>
                        <span>
                          {{ item.isReport == 1 ? "已上报" : "数据上报" }}
                        </span>
                      </div>
                      <!-- 复制 -->
                      <div class="operation-copy my-caozuo" @click="copy(item.copy)">
                        <span>
                          <span>
                            <img src="../../assets/img/views/fuzhi.png" alt />
                          </span>
                        </span>
                        <span>复制全部</span>
                      </div>
                      <div @click=" quickCopy(item)" class="my-caozuo">
                        <span style="margin:0;color:black;font-size:16px" class="el-icon-link"></span>
                        复制链接和标题
                      </div>
                      <!-- 截图 -->
                      <div class="operation-jietu my-caozuo" :class="item.disabled || item.fastPic ? 'disabled' : ''"
                        @click=" screenShot(item)">
                        <span>
                          <span>
                            <img src="../../assets/img/views/tupian.png" alt />
                          </span>
                        </span>
                        <span>页面截图</span>
                      </div>
                      <!-- 收藏 -->
                      <div v-if="activeName == 'first'" class="operation-shoucang my-caozuo" @click=" collect(item)">
                        <span>
                          <span>
                            <img v-if="item.isCollect == '1'" src="../../assets/img/views/yellowsc.png" alt />
                            <img v-else src="../../assets/img/views/shoucang.png" alt />
                          </span>
                        </span>
                        <span :class="[item.isCollect == '1' ? 'yishoucang' : ' ']">{{ item.isCollect == "1" ? "已收藏" :
        "收藏" }}</span>
                      </div>
                      <div v-if="activeName == 'first'" class="operation-delete my-caozuo" @click=" deletes(item)">
                        <span>
                          <span>
                            <img src="../../assets/img/views/delete.png" alt />
                          </span>
                        </span>
                        <span>删除</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 精简 -->
              <template v-else>
                <div class="bottom-jingjian-card">
                  <div class="bottom-jingjian-card-L">
                    <div v-if="activeName == 'second'" class="type english">
                      {{ item.languageType }}
                    </div>
                    <div v-if="activeName == 'first'" :class="['type', item.sensitives == 0 ? 'english' : 'red']">
                      {{ item.sensitives == 0 ? "非敏感" : "敏感" }}
                    </div>
                    <p class="bottom-title M-L" v-html="item.title ? item.title : ''" @click=" hangel(item)"></p>
                    <span class="original" @click=" lookOriginal(item.website)">
                      <img style="position: relative;top: 0px;" src="../../assets/img/views/wenzhang.png" alt />
                      <span>查看原文</span>
                    </span>
                  </div>
                  <div class="bottom-jingjian-card-R">
                    <span style="font-size: 14px;color: #888;">
                      {{ item.releaseTime ? item.releaseTime : "无" }}
                    </span>
                    <span v-if="activeName == 'first'" class="M-L" :class="[
        'comment-xiangsi',
        !item.likeAmount ? 'grey' : 'blue',
      ]
        ">
                      相似({{ item.likeAmount ? item.likeAmount : "0" }})
                      <!-- {{ !item.likeAmount ? "无相似" : "有相似" }} -->
                    </span>
                    <span class="comment-weidu" v-if="activeName == 'first'">
                      <i>
                        <img v-if="item.isRead == '1'" src="../../assets/img/views/greenmessage.png" alt />
                        <img v-else src="../../assets/img/views/redmessage.png" alt />
                      </i>
                      <span :class="[item.isRead == '1' ? 'yidu' : ' ']">
                        {{ item.isRead == "1" ? "已读" : "未读" }}
                      </span>
                    </span>
                  </div>

                </div>
              </template>
            </div>
          </div>
          <div class="analyse-container" v-else>
            <div class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <!-- 每一个模块 -->
        <div class="block">
          <span class="total">
            共{{ tablePage.total }}条, 当前显示第{{
        tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
      }}-{{
          tablePage.page * tablePage.pageSize > tablePage.total
            ? tablePage.total
            : tablePage.page * tablePage.pageSize
        }}条
          </span>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
            layout="sizes, prev, pager, next,jumper" :total="tablePage.total"></el-pagination>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import deleteDialog from "../../components/deleteDialog";
import {
  abroadList,
  countryList,
  editLabel,
  topList,
  deleteList,
  translate,
  message
} from "../../api/abroad/list";
import { setLabel, screenShot } from "../../api/accurate/list";
import { deleteLabel } from "../../api/abroad/list";
import { dataReport } from "../../api/message/list";

export default {
  data() {
    return {
      radioValue: 2, // 精简1 || 列表2
      loading: false,
      zhankai: false,
      //已预警
      checked: false,
      // 媒体类型
      mediaAll: false,
      media: [
        "新闻网站",
        "社区论坛",
        "社交媒体",
        "机构官网",
        "资讯平台",
        "其他",
      ],
      checkedMedia: [],
      // 媒体归属
      allMediaAscrip: false,
      mediaAscription: [
        "美国",
        "日本",
        "英国",
        "印度",
        "澳大利亚",
        "中东",
        "加拿大",
        "其他",
      ],
      checkedAscription: [],
      // 语言类型
      language: [],
      checkedLanguage: [],
      // 媒体阵营
      mediaCamp: [
        "邪教",
        "台独",
        "疆独",
        "藏独",
        "民主",
        "港独",
        "反华",
        "其他",
      ],
      //  敏感性是否全选
      sensitiveAll: false,
      // 敏感性选中数组
      sensitiveList: [],
      // 敏感性
      sensitiveData: [
        {
          value: 0,
          label: "非敏感",
        },
        {
          value: 1,
          label: "敏感",
        },
      ],
      // 媒体阵营全选,状态
      allCheckmediazy: false,
      checkedMediaCamp: [],

      // 表单
      formInline: {
        user: "",
        region: "title",
        input1: "",
        value1: [],
      },
      textData: [],
      // 设置标签
      biaoqianList: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      activeName: sessionStorage.getItem("activeName1")
        ? sessionStorage.getItem("activeName1")
        : "second",
      dataList: {},
      languages: {}
    };
  },
  components: {
    deleteDialog,
  },
  methods: {
    // 用Promise 封装setTimeout
    setTimeout: (time) => new Promise(res => setTimeout(res, time)),
    // 精简 || 列表  
    async changeradio() {
      this.loading = true
      await this.setTimeout(300)
      this.loading = false
    },
    //媒体类型全选
    handleCheckAreasChanges(val) {
      this.checkedMedia = val ? this.media : [];
    },
    //媒体类型 选中
    mediaTypeChecked(value) {
      let checkedCount = value.length;
      this.mediaAll = checkedCount === this.media.length;
    },
    //语言类型
    handleCheckAreasChangessss(val) {
      if (val.value == "全选") {
        this.checkedLanguage = [];
        this.language.forEach((item) => {
          item.checked = val.checked;
        });
      } else {
        for (let i = 1; i < this.language.length; i++) {
          if (this.language[i].checked == false) {
            this.language[0].checked = false;
            break;
          } else {
            this.language[0].checked = true;
          }
        }
      }
    },
    //媒体阵营
    handleCheckAreasChangesssss(val) {
      this.checkedMediaCamp = val ? this.mediaCamp : [];
    },
    // 媒体阵营的选中
    mediaCampChacked(value) {
      let checkedCount = value.length;
      this.allCheckmediazy = checkedCount === this.mediaCamp.length;
    },
    // 媒体归属全部选中
    allMediaAscription(val) {
      this.checkedAscription = val ? this.mediaAscription : [];
    },
    // 媒体归属选中
    mediaAscriptionChange(value) {
      let checkedCount = value.length;
      this.allMediaAscrip = checkedCount === this.mediaAscription.length;
    },
    // 敏感性全选
    sensitiveAllChange(val) {
      this.sensitiveList = val ? this.sensitiveData : [];
    },
    sensitiveGroup(value) {
      let checkedCount = value.length;
      this.sensitiveAll = checkedCount === this.sensitiveData.length;
    },
    // 获取条件
    async conditionList() {
      const res = await topList({});
      if (res != undefined) {
        if (res.data.data) {
          res.data.data.forEach((x) => {
            if (x.id === 3) {
              this.language = x.value.split(",").map((x) => {
                return {
                  value: x === "全部" ? "全选" : x,
                  checked: false,
                };
              });
            }
          });
        }
      }
    },
    //跳转详情
    hangel(item) {
      if (item.isRead == 0) {
        item.isRead = 1;
      }
      let routeUrl = this.$router.resolve({
        path: "/sensitiveMessage/index/xiangqing",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.dataId,
              type: "境外",
              activeName: this.activeName,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    //复制
    copy(msg) {
      this.$publicFun.copyFun(msg);
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
    },
    //数据上报
    async dataReport(item) {
      if (item.isReport === 1) {
        this.$message({
          message: "请勿重复上报!",
          type: "warning",
        });
        return false;
      }
      let data = {
        infoType: 2,
        inforMsgId: item.dataId,
        organizationId: this.$store.state.user.organizationId,
      };
      const res = await dataReport(data);
      if (res != undefined) {
        if (res.data.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: "warning",
          });
        } else {
          this.$message({
            message: res.data.data.msg,
            type: "success",
          });
        }
      }
      setTimeout(() => {
        this.getList();
      }, 1000);
    },
    //收藏/取消
    async collect(item) {
      let res = await editLabel({
        dataId: item.dataId,
        organId: item.organId,
        isCollect: item.isCollect === 0 ? 1 : 0,
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        item.isCollect = item.isCollect === 0 ? 1 : 0;
      }
    },
    //查看原文
    lookOriginal(web) {
      window.open(web);
    },
    //页面截图
    async screenShot(item) {
      item.disabled = true;
      const res = await screenShot({
        typeStr: "OVERSEAS_DATA",
        infoId: item.dataId,
        orgaId: this.$store.state.user.organizationId,
        website: item.website,
        uuid: item.screenshotAddress,
      });
      this.$message({
        message: res.data.data.ResponseMsg,
        type: "success",
      });
    },
    // 删除
    deletes(item) {
      item.message = "这篇境外新闻";
      this.$refs.openDialog.openDialog(item);
    },
    async deleteFn(item) {
      const res = await deleteList({
        dataId: item.dataId,
        organId: this.$store.state.user.organizationId,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        if (this.textData.length == 1) {
          if (this.tablePage.page > 1) {
            this.tablePage.page = this.tablePage.page - 1;
          }
        }
      } else {
        this.$message.error("删除失败！");
      }
      setTimeout(() => {
        this.getList();
      }, 1000);
    },
    zhankaiChange() {
      this.zhankai = !this.zhankai;
    },
    // 选项卡切换
    handleClick() {
      this.textData = [];
      this.dataList = {};
      this.sensitiveAll = false; //敏感性
      this.sensitiveList = []; //敏感性选中置为空
      sessionStorage.setItem("activeName1", this.activeName);
      this.formInline = {
        // 表单标题
        region: "title",
        // 作者模糊搜索
        input1: "",
        // 事件
        value: "",
        // 时间
        value1: [],
      };
      this.mediaAll = false;
      this.checkedMedia = [];
      this.allMediaAscrip = false;
      this.checkedAscription = [];
      this.allCheckmediazy = false;
      this.checkedMediaCamp = [];
      this.tablePage.page = 1;
      this.language.forEach((x) => {
        x.checked = false;
      });
      this.checked = false;
      this.getList();
    },
    // 时间框获得焦点
    focusDate() {
      this.formInline.value1 = [];
      this.formInline.value1[0] =
        this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00";
      this.formInline.value1[1] = this.$calcu.timeCycle(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    search() {
      if (this.formInline.value1[0] && this.formInline.value1[1] && this.formInline.value1[0] >= this.formInline.value1[1]) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if (!this.formInline.value1[0] || !this.formInline.value1[1]) {
        this.formInline.value1 = [];
      }
      let data = {};
      if (this.checkedMedia.length != 0) {
        data.mediaType = this.checkedMedia.join(",");
      }
      // 是否敏感
      if (!this.sensitiveAll) {
        if (this.sensitiveList.length != 0) {
          this.sensitiveList.forEach((item) => {
            data.sensitives = item.value;
          });
        }
      }
      if (this.checkedAscription.length != 0) {
        data.mediaOwnership = this.checkedAscription.join(",");
      }
      this.language.forEach((item) => {
        if (item.checked == true) {
          this.checkedLanguage.push(item.value);
        }
      });
      if (this.checkedLanguage.length != 0) {
        data.languageType = this.checkedLanguage.join(",");
      }
      if (this.checkedMediaCamp.length != 0) {
        data.mediaCamp = this.checkedMediaCamp.join(",");
      }
      //标题 正文  作者 region
      if (this.formInline.input1 != "") {
        if (this.formInline.region == "source") {
          let textChange = this.formInline.input1.trim().split(/\s+/g);
          data[this.formInline.region] = textChange.toString(",");
        } else {
          data[this.formInline.region] = this.formInline.input1;
        }
        // data[this.formInline.region] = this.formInline.input1;
      }
      //时间
      if (this.formInline.value1 != false && this.formInline.value1[0] && this.formInline.value1[1]) {
        data.startTimeStr =
          this.formInline.value1[0].replace(" ", "T") + "+08:00";
        data.endTimeStr =
          this.formInline.value1[1].replace(" ", "T") + "+08:00";
      }
      // 预警
      if (this.checked) {
        data.isEarlyWarn = 1;
      }
      this.tablePage.page = 1;
      this.getList(data);
    },
    // 获取列表
    async getList(obj) {
      let timer;
      if (this.activeName == "first") {
        timer = this.$calcu.calcuTime(90);
      } else {
        timer = this.$calcu.calcuTime(7);
      }
      this.loading = true;
      this.checkedLanguage = [];
      let data = {
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
        startTimeStr: timer + "T00:00:00+08:00",
        endTimeStr:
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00",
      };
      let res;
      if (obj) {
        this.dataList = obj;
        this.dataList.pageSize = this.tablePage.pageSize;
        this.dataList.pageNum = this.tablePage.page;
        if (this.dataList.startTimeStr) {
          data.startTimeStr = this.dataList.startTimeStr;
        } else {
          this.dataList.startTimeStr = data.startTimeStr;
        }
        if (this.dataList.endTimeStr) {
          data.endTimeStr = this.dataList.endTimeStr;
        } else {
          this.dataList.endTimeStr = data.endTimeStr;
        }
        data = this.dataList;
      } else if (Object.values(this.dataList).length > 0) {
        data = this.dataList;
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = this.tablePage.page;
      }
      if (this.activeName == "first") {
        data.organId = this.$store.state.user.organizationId;
        res = await abroadList(data);
      } else {
        res = await countryList(data);
      }
      if (res.data.data.result) {
        this.textData = res.data.data.result;
        this.tablePage.total = res.data.data.total;
        this.textData.forEach((item) => {
          item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
          if (item.keyword) {
            item.keywordList = item.keyword.split(" ");
            item.keywordArry = item.keyword.split(" ");
            if (item.keywordList.length > 3) {
              item.keywordList = item.keywordList.slice(0, 3);
            }
            let reg = new RegExp(" ", "g"); //g代表全部
            item.keyword = item.keyword.replace(reg, "<br/>");
          }
        });
        // this.heightText();
        let arrList = [];
        let idList = [];
        this.biaoqianList.forEach((item) => {
          arrList.push(item.labelId);
        });
        this.textData.forEach((item) => {
          if (item.setLabel) {
            if (arrList.indexOf(item.setLabel) == -1) {
              idList.push(item.setLabel);
            }
          }
        });
        idList.forEach((item) => {
          this.textData.forEach((ite) => {
            if (ite.setLabel == item) {
              ite.setLabel = null;
            }
          });
        });
      }
      this.loading = false;
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getList();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.getList();
      window.scrollTo(0, 0);
    },
    //获取标签
    async setLabel() {
      const res = await setLabel({
        organizationId: this.$store.state.user.organizationId,
        noShow: 1,
        isDel: 1,
      });
      if (res.data.rows) {
        this.biaoqianList = res.data.rows;
        if (this.biaoqianList.length == 1) {
          this.biaoqianList = [];
        }
      }
    },
    // 修改标签
    async changeLabel(val) {
      if (val.setLabel == -1) {
        const res = await deleteLabel({
          dataId: val.dataId,
          organId: val.organId,
        });
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功,已取消标签！",
            type: "success",
          });
        } else {
          this.$message.error("操作失败，请稍后再试!");
        }
        this.getList();
        return;
      }
      let res = await editLabel({
        dataId: val.dataId,
        organId: this.$store.state.user.organizationId,
        setLabel: val.setLabel,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "修改标签成功！",
          type: "success",
        });
      } else {
        this.$message.error("修改标签失败！");
      }
    },
    // 重置
    chongzhi() {
      this.dataList = {};
      this.formInline = {
        // 表单标题
        region: "title",
        // 作者模糊搜索
        input1: "",
        // 事件
        value: "",
        // 时间
        value1: [],
      };
      this.sensitiveAll = false; //敏感性
      this.sensitiveList = []; //敏感性选中置为空
      this.mediaAll = false;
      this.checkedMedia = [];
      this.allMediaAscrip = false;
      this.checkedAscription = [];
      this.allCheckmediazy = false;
      this.checkedMediaCamp = [];
      this.language.forEach((x) => {
        x.checked = false;
      });
      this.checked = false;
      this.getList();
    },
    checkAgainInfo(data2) {
      //境外信息跳转过来请求
      let data = {};
      this.loading = true;
      for (let i in data2) {
        switch (i) {
          case "languageType":
            if (data2[i]) {
              data[i] = data2[i];
            }
            break;
          case "mediaOwnership":
            if (data2[i]) {
              data[i] = data2[i];
            }
            break;
          case "mediaType":
            if (data2[i]) {
              data[i] = data2[i];
            }
            break;
          case "mediaCamp":
            if (data2[i]) {
              data[i] = data2[i];
            }
            break;
        }
      }
      if (data2.warn) {
        data.isEarlyWarn = 1;
      }
      data.startTimeStr = data2.startTimeStr;
      data.endTimeStr = data2.endTimeStr;
      this.dataList = data;
    },
    translateYes(data) {
      let obj = {
        "title": data.title,
        "content": data.content
      }
      translate(obj).then(res => {
        if (res.data.code == 200 && res.data.data.content) {
          data.titleF = res.data.data.title.replace(/<.+?>/g, " ");
          data.contentF = res.data.data.content.replace(/<.+?>/g, " ");
          this.$forceUpdate();
        }
      })
    }
  },
  created() {
    if (this.$route.query.info) {
      this.activeName = "first";
      this.checkAgainInfo(
        JSON.parse(decodeURIComponent(this.$route.query.info))
      );
      this.$router.push({ query: {} });
    }
    this.setLabel();
    this.getList();
    this.conditionList();
    message().then(res => {
      if (res.data.code == 200) {
        this.languages = res.data.data;
      }
    });
  }
};
</script>

<style scoped>
.M-L {
  margin-left: 10px;
}

::v-deep .el-radio-button--medium .el-radio-button__inner {
  padding: 6px 8px;
}

.message-count {
  padding: 24px 24px 0 24px;
}

.abroad .el-tabs {
  height: 40px;
}

.message-count .message-top {
  padding: 0 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 24px;
}

.message-count .message-region {
  line-height: 52px;
  width: 100%;
  position: relative;
}

.message-count .message-time {
  height: 68px;
  width: 100%;
}

.line {
  width: 100%;
  height: 1px;
  background: #e4e6ec;
}

.message-count .message-top-title {
  margin-right: 40px;
  margin-top: 16px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  float: left;
}

.message-count .huanjie {
  margin-right: 40px;
}

.message-count .el-checkbox-group {
  height: 100%;
  display: inline-block;
  width: 87%;
}

.xia-span-img {
  transform: rotate(-90deg);
}

.message-count .el-checkbox .el-checkbox__input {
  width: 14px;
  height: 14px;
}

.message-count .demo-form-inline {
  padding-top: 16px;
  height: 100%;
}

.message-count .select-item,
.el-select .select {
  margin: 0;
  width: 80px;
  height: 36px;
}

.message-count .input {
  width: 324px;
  margin-left: 32px;
}

.message-count .el_font {
  margin-left: 32px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.message-count .message-bottom {
  padding-bottom: 20px;
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
}

.bottom-count {
  display: flex;
  padding: 28px 40px;
  border-bottom: 1px solid #e4e6ec;
}

.bottom-count:hover {
  box-shadow: 0px 2px 4px 4px rgb(0 0 0 / 6%);
}

.bottom-count-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom-jingjian-card {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
}

.bottom-jingjian-card-L {
  display: flex;
}

.bottom-jingjian-card-R {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.bottom-line {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: #e4e6ec;
}

.bottom-title {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1008px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.bottom-count-text span {
  display: inline-block;
}

.bottom-count-text {
  display: inline-block;
  padding-left: 40px;
  width: calc(100% - 40px);
}

.type {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  width: 76px;
  height: 28px;
  line-height: 28px;
  border-radius: 1px;
}

.type:nth-child(2) {
  top: 115px;
}

.website {
  margin-top: 24px;
  width: 76px;
  height: 28px;
  background: #868bff;
  border-radius: 1px;
}

.english {
  width: 76px;
  height: 28px;
  background-color: #3e82ff;
}

.publics {
  font-size: 14px;
  color: #888;
  margin-top: 16px;
}

.bottom-text {
  cursor: pointer;
  line-height: 20px;
  overflow: hidden;
  height: 40px;
  font-size: 14px;
  color: #444;
  margin-top: 16px;
  word-break: break-all;
  width: 95%;
}

.bottom-meiti {
  font-size: 14px;
  color: #888;
}

.message-region .el-checkbox {
  margin-right: 48px;
}

.yujing {
  margin-left: 32px;
}

.comment-weidu {
  color: #ea3342;
}

.bottom-comment {
  font-size: 14px;
}

.comment-pinglun {
  color: #2e59ec;
  margin-right: 16px;
}

.comment-xiangsi {
  margin-right: 24px;
}

.bottom-operation {
  justify-content: space-between;
  display: flex;
  margin-top: 16px;
}

.bottom-operation img {
  width: 16px;
  height: 16px;
}

.weidu {
  margin-left: 5px;
  color: #ea3342;
}

.yidu {
  color: #02bc7c;
}

.yishoucang {
  color: #ff9000;
}

.yiyujing {
  color: #ea3342;
}

.comment-weidu img {
  width: 16px;
  height: 12px;
}

.analyse-container {
  width: 100%;
  height: 450px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.original {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
  margin-left: 16px;
}

.original img {
  position: relative;
  top: 4px;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.comment-weidu span {
  margin-left: 5px;
}

.my-caozuo {
  margin-left: 40px;
}

.bottom-comment-box {
  padding-right: 17px;
  justify-content: space-between;
  display: flex;
}

.bottom-text-box {
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
}

.bottom-text-caozuo {
  display: flex;
  align-items: center;
}

.bottom-text-caozuo>span:not(:first-child) {
  margin-left: 60px;
}

.operation-box {
  display: flex;
  font-size: 14px;
  margin-right: 16px;
  color: #333333;
  line-height: 20px;
}

.operation-box>div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.styleMargin {
  margin-right: 5px;
}

.operation-box>div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.operation-disabled {
  cursor: not-allowed;
}

.comment-box-yujing {
  width: 60px;
  margin-right: 16px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  background: #ffedef;
  color: #ea3342;
}

.zhankai-change {
  display: flex;
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
}

.xia-span {
  font-size: 14px;
  color: #2e59ec;
  width: 41px;
  display: inline-block;
  vertical-align: middle;
}

.xia-img {
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
}

.chongzhi {
  color: #333333;
  width: 60px;
  font-size: 14px;
  height: 32px;
  box-sizing: border-box;
}

.animation {
  transition: all 0.7s;
}

.defultHeight {
  height: 0px;
  overflow: hidden;
}

.shouHeight {
  overflow: hidden;
}

.message-count .block .total {
  left: 7%;
}

.zb_disabled {
  opacity: 0.5;
  cursor: default !important;
  pointer-events: none;
}

.translateDiv {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}
</style>
